import { useContext, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { format } from "fecha";
import { isArray } from "lodash";
import { AllValidReportTypes, ReportDocIds, ReportLanguage, ReportType } from "@parallel/vertex/enums/report.org.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { getReportReviewersByReportType } from "@parallel/vertex/util/report.org.util";
import ConfirmationModal, { ConfirmAction } from "@/components/common/content/ConfirmationModal";
import { FormStep } from "@/components/common/elements/form/FormStep";
import { Checkbox } from "@/components/common/elements/input/Checkbox";
import Select from "@/components/common/elements/select/Select";
import config from "@/config";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { currTimeOnDate } from "@/utils/datetime";
import ConfigWindowLayout from "./ConfigWindowLayout";

const SELECT_STYLE_CLASSES = "xs:w-[180px] sm:w-[220px] md:w-[260px] h-[42px]";

const PropertiesConfig = ({ report, clientFullName }: { report: AssessmentReport; clientFullName: string }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const existingDueDate = report.dueDate?.epochMs ? new Date(report.dueDate.epochMs) : undefined;

  const selectedReportType = report.type;
  const dueDate = existingDueDate ? existingDueDate : new Date();
  const isEnglish = report.language === ReportLanguage.English ? true : false;
  const selectedReviewerUserId = report.reviewerUserId ? report.reviewerUserId : undefined;
  const [confirmAction, setConfirmAction] = useState<ConfirmAction | undefined>(undefined);

  const updateReport = ({
    reviewerUserId,
    type,
    language,
    dueDate,
  }: {
    reviewerUserId?: string;
    type?: ReportType;
    language?: ReportLanguage;
    dueDate?: Date;
  }) => {
    return reportStore.updateRemoteReport({
      name: "update report properties",
      fn: () =>
        reportApi.updateReport(report.id, {
          reviewerUserId,
          type,
          language,
          dueDateTimestamp: dueDate && format(dueDate, "isoDateTime"),
        }),
      updateLocalConfig: true,
    });
  };

  const consentSignedDate = report.consentSignedAt?.epochMs ? new Date(report.consentSignedAt.epochMs) : undefined;
  const selectedReportLanguage = isEnglish ? ReportLanguage.English : ReportLanguage.Billingual;
  const templateDocId = selectedReportType ? ReportDocIds[selectedReportType][selectedReportLanguage] : "";
  const availableReviewers = selectedReportType ? getReportReviewersByReportType(config, selectedReportType) : [];

  const handleDueDateChange = async (updatedDueDate: Date) => {
    await updateReport({ dueDate: updatedDueDate });
  };

  const handleReportLanguageChange = async (languageSelection: boolean) => {
    await updateReport({ language: languageSelection ? ReportLanguage.English : ReportLanguage.Billingual });
  };

  const handleConfirmationActionChange = (updatedConfirmAction: ConfirmAction) => {
    setConfirmAction(updatedConfirmAction);
  };

  const handleReportTypeChange = async (updatedType: ReportType) => {
    await updateReport({ type: updatedType });
  };

  const handleReportReviewerChange = async (updatedReviewerId: string) => {
    await updateReport({ reviewerUserId: updatedReviewerId });
  };

  return (
    <ConfigWindowLayout title="Report Properties" subtitle="Edit existing properties of the report">
      <div className="w-full flex flex-col">
        <FormStep label="Student">
          <div className={`${SELECT_STYLE_CLASSES} my-2`}>
            <div className="w-full  bg-white rounded-md flex flex-row place-items-center justify-between h-[42px]">
              <div className="flex flex-row items-baseline gap-2">
                <div className="text-md">{clientFullName}</div>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep label="Report Type">
          <div className="w-full py-2">
            <div className="flex flex-col gap-2">
              <Select
                label="type-select"
                options={AllValidReportTypes.map(t => ({ name: t, value: t }))}
                value={report.type}
                placeholder="Select a report type..."
                className={SELECT_STYLE_CLASSES}
                onChange={selected =>
                  handleConfirmationActionChange({
                    action: () => handleReportTypeChange(selected as ReportType),
                    name: "report-type-change",
                    spec: {
                      title: "Change Report Type",
                      prompt:
                        "If the report isn’t built yet, changing the report type will change the options under measured used and test interpretations. If the report is built, then nothing about the report doc will change.",
                      submitButtonText: "Submit",
                    },
                  })
                }
              />

              <div className="flex flex-row space-x-16 items-center">
                <Checkbox
                  id="report-language"
                  label="Is Bilingual?"
                  isChecked={!isEnglish}
                  toggle={() => handleReportLanguageChange(!isEnglish)}
                />
                <a
                  href={`https://docs.google.com/document/d/${templateDocId}/view`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-ocean underline italic"
                >
                  Preview Template
                </a>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep label="Consent Signed Date">
          <div className={`${SELECT_STYLE_CLASSES} my-2`}>
            <div className="w-full  bg-white rounded-md flex flex-row place-items-center justify-between h-[42px]">
              <div className="flex flex-row items-baseline gap-2">
                <div className="text-md">
                  {consentSignedDate ? format(consentSignedDate, "MMMM D, YYYY") : "No Consent Signed Date"}
                </div>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep label="Report Due Date">
          <div className={`my-2 w-full flex flex-col`}>
            <DatePicker
              onChange={value => {
                const date = isArray(value) ? value[0] : value;
                date && handleDueDateChange(currTimeOnDate(date));
              }}
              className={SELECT_STYLE_CLASSES}
              value={dueDate}
            />
          </div>
        </FormStep>
        <FormStep label="Reviewer" lastStep={true}>
          <div className="w-full py-2">
            <Select
              className={SELECT_STYLE_CLASSES}
              label="reviewer-select"
              placeholder="Select a reviewer..."
              options={availableReviewers.map(r => ({ name: r.name, value: r.userId }))}
              value={selectedReviewerUserId}
              onChange={selected => handleReportReviewerChange(selected)}
            />
          </div>
        </FormStep>
      </div>
      {!!confirmAction && <ConfirmationModal {...confirmAction} onClose={() => setConfirmAction(undefined)} />}
    </ConfigWindowLayout>
  );
};
export default PropertiesConfig;
