import { useContext } from "react";
import { capitalize, get } from "lodash";
import { observer } from "mobx-react-lite";
import { allClinicalInterviewValueGroups, ReportValueGroup } from "@parallel/vertex/enums/report.org.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { INTERVIEW_FORM_TYPES, InterviewFormType } from "@/api/report.api";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { hasValues } from "@/utils/assessment";
import { ReportConfigSection, ReportEditorSection } from "../EditReport";
import EditReportSidebarEligibilityGroup from "./EditReportSidebarEligibilityGroup";
import EditReportSidebarGroup, { SidebarGroupSectionItem, sidebarHeaderButton } from "./EditReportSidebarGroup";
import EditReportSidebarRecommendationGroup from "./EditReportSidebarRecommendationGroup";
import EditReportSidebarUploadGroup, { getValueGroupAction } from "./EditReportSidebarUploadGroup";

export const getInterviewFormTypeSidebarItem = (
  report: AssessmentReport,
  formType: InterviewFormType,
): SidebarGroupSectionItem | undefined => {
  const submissionId = get(report.formSubmissionIds?.interview, formType);
  if (!submissionId) return;
  return { type: { sectionType: capitalize(formType), subType: "InterviewForm", id: submissionId } };
};

const EditReportSidebar = ({
  report,
  selectedSection,
  setSelectedSection,
  setIsAddingInterviewForm,
  setIsUploading,
  setIsAddingEligibility,
  setIsAddingNeed,
  moveSelection,
}: {
  report: AssessmentReport;
  selectedSection?: ReportEditorSection;
  setSelectedSection: (s: ReportEditorSection) => void;
  setIsAddingInterviewForm: (b: boolean) => void;
  setIsUploading: (b: boolean) => void;
  setIsAddingEligibility: (b: boolean) => void;
  setIsAddingNeed: (b: boolean) => void;
  moveSelection: (delta: number) => void;
}) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);
  const clinicalInterviewSections: SidebarGroupSectionItem[] = [];
  const hasReportBeenBuilt = !!report.docId;

  const customizeSectionItems: SidebarGroupSectionItem[] = [
    { sectionType: ReportConfigSection.TestingPlan },
    { sectionType: ReportConfigSection.SectionsToInclude },
  ].map(type => ({ type }));

  allClinicalInterviewValueGroups.forEach((reportValueGroup: ReportValueGroup) => {
    if (hasValues(report, reportValueGroup)) {
      clinicalInterviewSections.push({
        type: { sectionType: reportValueGroup, subType: "ReportValueGroup" },
        action: getValueGroupAction(
          reportStore,
          reportApi,
          reportValueGroup,
          report,
          selectedSection?.sectionType === reportValueGroup,
          moveSelection,
        ),
      });
    }
  });

  customizeSectionItems.push(...clinicalInterviewSections, {
    type: {
      sectionType: hasReportBeenBuilt ? ReportConfigSection.ResetStructure : ReportConfigSection.FinalizeStructure,
    },
  });

  const interviewNotesItems: SidebarGroupSectionItem[] = mapExists([...INTERVIEW_FORM_TYPES], type =>
    getInterviewFormTypeSidebarItem(report, type),
  );

  return (
    <div className="w-[300px] h-full shrink-0 flex flex-col border-r border-slate-200 bg-blue-secondary">
      <div className="w-full flex flex-col p-2 gap-2 overflow-y-auto">
        <EditReportSidebarGroup
          headerText="Customize Report Structure"
          sectionItems={customizeSectionItems}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
        {report.formSubmissionIds && (
          <EditReportSidebarGroup
            headerText="Interview Notes"
            headerAction={sidebarHeaderButton({
              onClick: () => setIsAddingInterviewForm(true),
              label: "add-interview",
            })}
            sectionItems={interviewNotesItems}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
          />
        )}
        <EditReportSidebarUploadGroup
          report={report}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          setIsUploading={setIsUploading}
          moveSelection={moveSelection}
        />
        <EditReportSidebarEligibilityGroup
          report={report}
          selectedSection={selectedSection}
          moveSelection={moveSelection}
          setSelectedSection={setSelectedSection}
          setIsAddingEligibility={setIsAddingEligibility}
        />
        <EditReportSidebarRecommendationGroup
          report={report}
          selectedSection={selectedSection}
          moveSelection={moveSelection}
          setSelectedSection={setSelectedSection}
          setIsAddingNeed={setIsAddingNeed}
        />
      </div>
    </div>
  );
};

export default observer(EditReportSidebar);
