import { useContext, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { useAsync, useKeyboardEvent, useMountEffect } from "@react-hookz/web";
import { capitalize, isString } from "lodash";
import { observer } from "mobx-react-lite";
import { AllReportValueGroups, ReportValueGroup } from "@parallel/vertex/enums/report.org.enums";
import { ExtendedFormSubmission } from "@parallel/vertex/types/form.types";
import { Need } from "@parallel/vertex/types/report.org.guidance.types";
import { AssessmentReport, HydratedAssessmentReport, NeedGroup } from "@parallel/vertex/types/report.org.types";
import { findNeedGroup } from "@parallel/vertex/util/report.org.util";
import { INTERVIEW_FORM_TYPES, InterviewFormType } from "@/api/report.api";
import ConfigWindowLayout from "@/components/assessment/report/edit/config/ConfigWindowLayout";
import FormSubmission from "@/components/assessment/report/edit/config/form/FormSubmission";
import { SubHeaderValidationItem } from "@/components/assessment/report/edit/header/SubHeaderControls";
import Modal from "@/components/common/content/Modal";
import { MultiSelectModal, SingleSelectModal } from "@/components/common/content/SelectModal";
import { Button } from "@/components/common/elements/button/Button";
import Select from "@/components/common/elements/select/Select";
import { ReportStoreContext, alertStore, ApiStoreContext } from "@/store";
import { HydratedReportEligibility } from "@/store/report.store";
import { hasValues } from "@/utils/assessment";
import FinalizeStructureConfig from "./config/FinalizeStructureConfig";
import MeasuresUsedConfig from "./config/MeasuresUsedConfig";
import PropertiesConfig from "./config/PropertiesConfig";
import SectionsConfig from "./config/SectionsConfig";
import SingleEligibilityConfig from "./config/eligibility/SingleEligibilityConfig";
import SingleRecommendationConfig from "./config/recommendation/SingleRecommendationConfig";
import { getNeedTitle } from "./config/recommendation/utils";
import EditReportHeader from "./header/EditReportHeader";
import UpdateDocModal from "./modals/UpdateDocModal";
import UploadSourceFilesModal from "./modals/UploadSourceFilesModal";
import EditReportSidebar, { getInterviewFormTypeSidebarItem } from "./sidebar/EditReportSidebar";
import ReportValues from "./values/ReportValues";

export enum ReportConfigSection {
  TestingPlan = "Testing Plan",
  MeasuresUsed = "Measures Used",
  SectionsToInclude = "Sections To Include",
  Eligibilities = "Eligibilities",
  Recommendations = "Recommendations",
  Properties = "Properties",
  FinalizeStructure = "Finalize Structure",
  ResetStructure = "Reset Structure",
}

export type ReportSectionSubType = "InterviewForm" | "ReportValueGroup" | "Eligibility" | "Recommendation";
export type OldReportEditorSection = ReportValueGroup | ReportConfigSection | string;

export type ReportEditorSection = {
  sectionType: ReportValueGroup | ReportConfigSection | InterviewFormType | string;
  subType?: ReportSectionSubType;
  id?: string | string[];
};

export type ReportSectionContent = {
  currSection: ReportEditorSection | undefined;
  report: AssessmentReport;
  clientFullName?: string;
  hydratedEligibilities?: HydratedReportEligibility[];
  hydratedNeedGroups?: { needGroups?: NeedGroup[]; allNeeds: Need[] };
  testingPlan?: {
    submission: ExtendedFormSubmission;
    onSubmit: (data: Record<string, any>) => Promise<void>;
  };
};

const getSectionContent = ({
  currSection,
  report,
  clientFullName,
  hydratedEligibilities,
  hydratedNeedGroups,
  testingPlan,
}: ReportSectionContent) => {
  if (currSection?.sectionType === ReportConfigSection.TestingPlan) {
    return report.formSubmissionIds ? (
      <ConfigWindowLayout
        title="Testing Plan"
        subtitle="These selections will reflect which measures are used during assessment"
        isSectionLocked={!!report.docId}
        headerControls={
          <SubHeaderValidationItem
            text="Complete and submit the form below"
            isValid={!!testingPlan?.submission.formAnswers}
          />
        }
      >
        <FormSubmission
          submissionId={report.formSubmissionIds.testingPlan}
          isReadOnly={!!report.docId}
          onSubmit={testingPlan?.onSubmit}
          key={report.formSubmissionIds.testingPlan}
        />
      </ConfigWindowLayout>
    ) : (
      <MeasuresUsedConfig report={report} />
    );
  } else if (currSection?.sectionType === ReportConfigSection.MeasuresUsed) {
    return <MeasuresUsedConfig report={report} />;
  } else if (currSection?.sectionType === ReportConfigSection.SectionsToInclude) {
    return <SectionsConfig report={report} />;
  } else if (currSection?.sectionType === ReportConfigSection.Properties) {
    return <PropertiesConfig report={report} clientFullName={clientFullName ? clientFullName : "Name Not Available"} />;
  } else if (
    currSection?.sectionType === ReportConfigSection.FinalizeStructure ||
    currSection?.sectionType === ReportConfigSection.ResetStructure
  ) {
    return <FinalizeStructureConfig report={report} testingPlanSubmission={testingPlan?.submission} />;
  } else if (currSection?.subType === "InterviewForm" && isString(currSection.id)) {
    return (
      <ConfigWindowLayout
        title={`${capitalize(currSection.sectionType)} Interview Notes`}
        subtitle="Answers here will appear in the report"
      >
        <FormSubmission submissionId={currSection.id} key={currSection.id} />
      </ConfigWindowLayout>
    );
  } else {
    if (currSection?.subType && currSection.subType === "Eligibility") {
      const selectedEligibility = hydratedEligibilities?.find(e => e?.canonical?.name === currSection?.sectionType);
      if (!selectedEligibility) return <div>Selected eligibility not found</div>;
      return <SingleEligibilityConfig eligibility={selectedEligibility.canonical} report={report} />;
    } else if (currSection?.subType && currSection.subType === "Recommendation") {
      const selectedNeedGroup = hydratedNeedGroups?.needGroups?.find(group => {
        return currSection?.sectionType === getNeedTitle(group, hydratedNeedGroups.allNeeds || []);
      });

      if (!selectedNeedGroup) return <div>Selected areas of need not found</div>;
      const needGroupName = getNeedTitle(selectedNeedGroup, hydratedNeedGroups?.allNeeds || []);
      return (
        <SingleRecommendationConfig
          report={report}
          key={needGroupName}
          needGroupName={needGroupName}
          needGroup={selectedNeedGroup ? selectedNeedGroup : { needIds: [], recommendationIds: [] }}
        />
      );
    }

    const group = currSection?.sectionType as ReportValueGroup;
    const pendingValues = (report.pendingValues || {})[group] || [];
    const injectedValues = (report.injectedValues || {})[group] || [];
    return <ReportValues group={group} pendingValues={pendingValues} injectedValues={injectedValues} />;
  }
};

const EditReport = ({ hydratedReport }: { hydratedReport: HydratedAssessmentReport }) => {
  const report = hydratedReport.report;
  const reportStore = useContext(ReportStoreContext);
  const { formApi, reportApi, reportGuidanceApi } = useContext(ApiStoreContext);

  const eligibilities = reportStore?.reportEligibilities || undefined;
  const needGroups = report.needGroups;

  const [testingPlanSubmission, setTestingPlanSubmission] = useState<ExtendedFormSubmission>();

  const [{ result: allEligibilities }, { execute: fetchAllEligibilities }] = useAsync(() =>
    reportGuidanceApi.getAllEligibilities().catch(e => alertStore.setFailedProcess("fetch all eligibilities", e)),
  );

  const [{ result: allNeeds }, { execute: fetchAllNeeds }] = useAsync(() =>
    reportGuidanceApi.getAllNeeds().catch(e => alertStore.setFailedProcess("fetch all needs", e)),
  );

  useMountEffect(() => {
    fetchAllEligibilities();
    fetchAllNeeds();
    report.formSubmissionIds &&
      formApi.getSubmission(report.formSubmissionIds.testingPlan).then(setTestingPlanSubmission);
  });

  const [isAddingEligibility, setIsAddingEligibility] = useState(false);

  const dataSourceGroups: ReportEditorSection[] = AllReportValueGroups.filter(g => hasValues(report, g)).map(g => ({
    sectionType: g,
    subType: "ReportValueGroup",
  }));

  const allOrderedSections: ReportEditorSection[] = [
    report.formSubmissionIds
      ? { sectionType: ReportConfigSection.TestingPlan }
      : { sectionType: ReportConfigSection.MeasuresUsed },
    { sectionType: ReportConfigSection.SectionsToInclude },
    { sectionType: ReportConfigSection.FinalizeStructure },
    { sectionType: ReportConfigSection.Eligibilities },
    { sectionType: ReportConfigSection.Recommendations },
    ...dataSourceGroups,
  ];

  const selectEligibility = (eligibilityId: string) =>
    reportStore.updateRemoteReport({
      name: "add eligibility",
      fn: () => reportGuidanceApi.upsertEligibility(report.id, eligibilityId).then(({ report }) => report),
    });

  const [isUpdatingDoc, setIsUpdatingDoc] = useState(false);
  const [currSection, setCurrSection] = useState<ReportEditorSection | undefined>(
    dataSourceGroups[0] || allOrderedSections[0],
  );
  const [addInterviewFormType, setAddInterviewFormType] = useState<InterviewFormType>();
  const [isUploadingData, setIsUploadingData] = useState(false);
  const [isAddingNeed, setIsAddingNeed] = useState(false);

  const moveSelection = (indexDelta: number) => {
    const currIndex = allOrderedSections.findIndex(s => s === currSection);
    let newIndex = currIndex + indexDelta;
    if (newIndex < 0) newIndex = allOrderedSections.length - 1;
    if (newIndex >= allOrderedSections.length) newIndex = 0;

    setCurrSection(allOrderedSections[newIndex]);
  };

  const selectNeeds = (needIds: string[]) => {
    const existingGroup = findNeedGroup(report, needIds);

    if (!!existingGroup && existingGroup.index !== -1) return;
    reportStore.updateRemoteReport({
      name: "add needs",
      fn: () => reportGuidanceApi.upsertReportNeeds(report.id, needIds),
    });
  };

  useKeyboardEvent(true, ({ key }) => {
    if (!currSection) return;
    let indexDelta = 0;
    if (key === "ArrowUp") indexDelta = -1;
    if (key === "ArrowDown") indexDelta = 1;
    if (!indexDelta) return;
    moveSelection(indexDelta);
  });

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-row w-full">
        <EditReportHeader
          hydratedReport={hydratedReport}
          showPropertiesPage={() => setCurrSection({ sectionType: ReportConfigSection.Properties })}
          isSavingReport={reportStore.isUpdating}
          setIsUpdatingDoc={setIsUpdatingDoc}
          currSection={currSection}
        />
      </div>

      <div className="flex flex-row h-full overflow-y-auto">
        <EditReportSidebar
          report={report}
          selectedSection={currSection}
          setSelectedSection={setCurrSection}
          setIsAddingEligibility={setIsAddingEligibility}
          setIsAddingInterviewForm={() => setAddInterviewFormType(INTERVIEW_FORM_TYPES[0])}
          setIsUploading={setIsUploadingData}
          setIsAddingNeed={setIsAddingNeed}
          moveSelection={moveSelection}
        />

        <div className={`flex mx-auto h-full w-full justify-center overflow-y-auto ${currSection ? "bg-white" : ""}`}>
          {!!currSection &&
            getSectionContent({
              currSection,
              report,
              clientFullName: hydratedReport.user?.fullName,
              hydratedEligibilities: eligibilities,
              hydratedNeedGroups: {
                needGroups: needGroups || [],
                allNeeds: allNeeds || [],
              },
              testingPlan: testingPlanSubmission && {
                submission: testingPlanSubmission,
                onSubmit: data =>
                  reportApi
                    .submitTestingPlan(report.id, data)
                    .then(() => setTestingPlanSubmission({ ...testingPlanSubmission, formAnswers: data })),
              },
            })}
        </div>
      </div>

      {addInterviewFormType && (
        <Modal title="Add Interview Notes" onClose={() => setAddInterviewFormType(undefined)}>
          <div className="w-72 flex flex-col gap-4">
            <Select
              label="add-interview-type"
              options={INTERVIEW_FORM_TYPES.map(type => ({ value: type, name: capitalize(type) }))}
              value={addInterviewFormType}
              onChange={value => setAddInterviewFormType(value as InterviewFormType)}
              className="w-full"
            />
            <Button
              text="Submit"
              onClick={() =>
                reportStore.updateRemoteReport({
                  name: "add interview form",
                  fn: () => reportApi.addInterviewForm(report.id, addInterviewFormType),
                  sideEffect: updated => {
                    setAddInterviewFormType(undefined);
                    const newItem = getInterviewFormTypeSidebarItem(updated, addInterviewFormType);
                    newItem && setCurrSection(newItem.type);
                  },
                })
              }
              className="w-full"
            />
          </div>
        </Modal>
      )}
      {isUploadingData && <UploadSourceFilesModal report={report} onClose={() => setIsUploadingData(false)} />}
      {isUpdatingDoc && <UpdateDocModal onClose={() => setIsUpdatingDoc(false)} />}
      {isAddingEligibility && (
        <SingleSelectModal
          items={allEligibilities || undefined}
          selectItem={selectEligibility}
          itemName="Eligibility"
          onClose={() => setIsAddingEligibility(false)}
        />
      )}
      {isAddingNeed && (
        <MultiSelectModal
          items={allNeeds || undefined}
          selectItems={selectNeeds}
          itemName="Areas of Need"
          onClose={() => setIsAddingNeed(false)}
        />
      )}
    </div>
  );
};

export default observer(EditReport);
