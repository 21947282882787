import { useContext, useState } from "react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { observer } from "mobx-react-lite";
import { ReportStatus } from "@parallel/vertex/enums/report.org.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import ReviewConfirmModal from "@/components/assessment/report/edit/modals/ReviewConfirmModal";
import ConfirmationModal, { ConfirmAction } from "@/components/common/content/ConfirmationModal";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { classNames } from "@/utils";

const _ReportProgressStepper = ({ report }: { report: AssessmentReport }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const [isShowingSurvey, setIsShowingSurvey] = useState(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmAction | undefined>(undefined);

  const isReportComplete = report.status === ReportStatus.Complete;
  const hasReportBeenBuilt = !!report.docId && !!report.docBuiltAt;

  const handleConfirmationActionChange = (updatedConfirmAction: ConfirmAction) => {
    setConfirmAction(updatedConfirmAction);
  };

  // report completion progress for stepper
  const reportProgressSteps = [
    {
      key: "customize",
      label: "Customize",
      nextStep: "upload",
      prevStep: undefined,
    },
    {
      key: "upload",
      label: "Upload",
      nextStep: "interpret",
      prevStep: "customize",
      reportStatus: ReportStatus.ScoreEntry,
    },
    {
      key: "interpret",
      label: "Interpret",
      nextStep: "review",
      prevStep: "upload",
      reportStatus: ReportStatus.Interpretation,
    },
    {
      key: "review",
      label: "Review",
      nextStep: "complete",
      prevStep: "interpret",
      reportStatus: ReportStatus.Review,
    },
  ];

  // Reports that haven't been built will be in the `Customize` step
  // otherwise the reports map one to one to the existing report statuses
  const activeStep = isReportComplete
    ? 5
    : hasReportBeenBuilt
      ? reportProgressSteps.findIndex(step => step.reportStatus === report.status)
      : 0;

  const setStatus = (status?: ReportStatus, completedSurvey?: boolean) => {
    if (status) {
      return reportStore.updateRemoteReport({
        name: `update report status to ${status}`,
        fn: () => reportApi.setStatus(report.id, status, completedSurvey),
      });
    }
    return;
  };

  const handleStepChange = (index: number) => {
    const currentStep = reportProgressSteps[activeStep];
    if (index > activeStep) {
      const nextStepIndex = reportProgressSteps.findIndex(step => currentStep.nextStep === step.key);
      if (!nextStepIndex) return;

      const nextReportStatus = reportProgressSteps[nextStepIndex].reportStatus;
      const nextStatusLabel = reportProgressSteps[nextStepIndex].label;

      if (nextReportStatus === ReportStatus.Review) {
        setIsShowingSurvey(true);
      } else {
        handleConfirmationActionChange({
          action: () => setStatus(nextReportStatus),
          name: "report-next-status",
          spec: {
            title: `Change to ${nextStatusLabel}`,
            prompt: `Are you sure you want to change to ${nextStatusLabel}?`,
            submitButtonText: `Change to ${nextStatusLabel}`,
          },
        });
      }
    } else {
      const prevStepIndex = reportProgressSteps.findIndex(step => currentStep.prevStep === step.key);
      if (!prevStepIndex || prevStepIndex === 0) return;

      const prevReportStatus = reportProgressSteps[prevStepIndex].reportStatus;
      const prevStatusLabel = reportProgressSteps[prevStepIndex].label;

      handleConfirmationActionChange({
        action: () => setStatus(prevReportStatus),
        name: "report-prev-status",
        spec: {
          title: `Change to ${prevStatusLabel}`,
          prompt: `Are you sure you want to change to ${prevStatusLabel}?`,
          submitButtonText: `Change to ${prevStatusLabel}`,
        },
      });
    }
  };

  const isNextOrPreviousStep = (index: number) =>
    reportProgressSteps[activeStep + 1]?.key === reportProgressSteps[index]?.key ||
    (reportProgressSteps[activeStep - 1]?.key === reportProgressSteps[index]?.key && activeStep !== 1);

  return (
    <div className="w-full">
      <Stepper activeStep={activeStep} alternativeLabel>
        {reportProgressSteps.map((step, index) => (
          <Step
            key={step.key}
            disabled={!hasReportBeenBuilt || report.status === ReportStatus.Complete}
            completed={activeStep > index || report.status === ReportStatus.Complete}
            sx={{
              "& .Mui-completed": { fill: "green" },
            }}
          >
            {isNextOrPreviousStep(index) ? (
              <StepButton
                icon={activeStep === index && <MapPinIcon className="h-6 w-6 text-black" />}
                color="inherit"
                onClick={() => handleStepChange(index)}
                disabled={!hasReportBeenBuilt}
              >
                <p className={classNames(hasReportBeenBuilt ? "text-ocean hover:text-navy" : "text-black")}>
                  {step.label}
                </p>
              </StepButton>
            ) : (
              <StepLabel icon={activeStep === index && <MapPinIcon className="h-6 w-6 text-black" />} color="inherit">
                {step.label}
              </StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
      {isShowingSurvey && (
        <ReviewConfirmModal
          report={report}
          setReadyToReview={async () => await setStatus(ReportStatus.Review, true)}
          onClose={() => setIsShowingSurvey(false)}
        />
      )}

      {!!confirmAction && <ConfirmationModal {...confirmAction} onClose={() => setConfirmAction(undefined)} />}
    </div>
  );
};

export const ReportProgressStepper = observer(_ReportProgressStepper);
