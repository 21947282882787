import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { allReportReviewers } from "@parallel/vertex/util/report.org.util";
import Modal from "@/components/common/content/Modal";
import { Link } from "@/components/common/elements/Link";
import { Button } from "@/components/common/elements/button/Button";
import config from "@/config";

const ReviewConfirmModal = ({
  report,
  onClose,
  setReadyToReview,
}: {
  report: AssessmentReport;
  onClose: () => void;
  setReadyToReview: () => Promise<void> | undefined;
}) => {
  const handleSurveySubmission = async () => {
    await setReadyToReview();
    onClose();
  };
  const reviewer = allReportReviewers(config).find(r => r.userId === report.reviewerUserId);

  return (
    <Modal title="Ready for Review" onClose={onClose} containerStyle={{ maxWidth: "40%" }}>
      <div className="flex flex-col gap-8 place-items-center">
        <div className="flex flex-col gap-3">
          <p>Are you sure you want to set this report as Ready for Review?</p>
          <p>
            Don't forget to <Link href={`${config.pathwayClientUrl}/calendar`}>log your indirect time here</Link>.
          </p>
        </div>

        <div className="flex flex-col gap-3 place-items-center">
          {reviewer && (
            <div className="flex flex-row gap-2 items-center">
              <ExclamationTriangleIcon className="w-6 h-6 text-orange" />
              <p>{reviewer.name} will be alerted</p>
            </div>
          )}
          <Button text={"Ready For Review"} icon={<ChevronRightIcon />} onClick={handleSurveySubmission} />
        </div>
      </div>
    </Modal>
  );
};

export default observer(ReviewConfirmModal);
