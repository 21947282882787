import type { DeprecatedRecommendation } from "@prisma/client";
import { flatten, intersectionWith, isEqual, sortBy, uniqBy } from "lodash";
import { NoOverrideStaticConfig } from "../config/static.config";
import { ReportType, ReportVerticalByType } from "../enums/report.org.enums";
import { HydratedNeed } from "../types/report.org.guidance.types";
import { AssessmentReport, NeedGroup } from "../types/report.org.types";

export const allReportReviewers = (config: NoOverrideStaticConfig) =>
  uniqBy(flatten(Object.values(config.reportReviewers)), "userId");

export const getReportReviewersByReportType = (config: NoOverrideStaticConfig, type: ReportType) => {
  const vertical = ReportVerticalByType[type];
  return (vertical && config.reportReviewers[vertical]) || [];
};

export const isUserValidReportReviewer = (config: NoOverrideStaticConfig, userId?: string, reportType?: ReportType) => {
  if (!userId) return false;
  // check against all reviewers if a report type isn't specified
  if (!reportType) return allReportReviewers(config).some(u => u.userId === userId);

  const reportReviewerUserIds = getReportReviewersByReportType(config, reportType)?.map(r => r.userId);
  if (!reportReviewerUserIds) return true;
  return reportReviewerUserIds.includes(userId);
};

// given a series of needs and selected need ids, this returns the unique recommendations shared
// between them in a single list
export const getRecommendationsByNeedGroup = (needs: HydratedNeed[], needIds: string[]): DeprecatedRecommendation[] => {
  const recommendations: DeprecatedRecommendation[][] = needs
    .filter(n => needIds.includes(n.id))
    .map(need => need.recommendations);

  if (needIds.length === 1 && recommendations.length === 1) return recommendations[0];

  const sharedRecommendations: DeprecatedRecommendation[] = intersectionWith(...sortBy(recommendations), isEqual);

  const uniqueRecommendations = uniqBy(sharedRecommendations, "id");
  return uniqueRecommendations;
};

// Given a list of needIds, return the matching needGroup and report index,
// otherwise return undefined
export const findNeedGroup = (
  report: AssessmentReport,
  needIds: string[],
): { group: NeedGroup; index: number } | undefined => {
  if (!report.needGroups) return undefined;
  const index = report.needGroups.findIndex(needGroup => isEqual(sortBy(needGroup.needIds), sortBy(needIds)));
  if (index === -1) return undefined;

  return { group: report.needGroups[index], index };
};

// returns RegEx for finding our own custom tokens in a template string
// e.g. "#{ token }" or "#{token}"
export const getTokenRegex = (token: string) => new RegExp(`#{ *?${token} *?}`, "g");
