import { useContext } from "react";
import { MinusIcon } from "@heroicons/react/24/outline";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { getCommaList } from "@parallel/vertex/util/string.util";
import { ReportGuidanceAPI } from "@/api/report.guidance.api";
import { ApiStoreContext, ReportStoreContext, alertStore } from "@/store";
import { ReportStore } from "@/store/report.store";
import { ReportEditorSection } from "../EditReport";
import { getNeedTitle } from "../config/recommendation/utils";
import EditReportSidebarGroup, { SidebarGroupSectionItem, sidebarHeaderButton } from "./EditReportSidebarGroup";

export const removeNeedAction = (
  reportStore: ReportStore,
  reportGuidanceApi: ReportGuidanceAPI,
  report: AssessmentReport,
  isGroupSelected: boolean,
  needIds: string[],
  moveSelection: (delta: number) => void,
) => {
  const titleText = "Remove Need Group";

  return {
    icon: <MinusIcon />,
    helpText: titleText,
    fn: () =>
      reportStore.updateRemoteReport({
        name: `remove need group - ${getCommaList(needIds)}`,
        fn: () => reportGuidanceApi.removeReportNeeds(report.id, needIds),
        sideEffect: _updatedReport => {
          if (!isGroupSelected) return;
          moveSelection(1);
        },
        confirmSpec: {
          title: titleText,
          prompt: `The need group will be removed from the report. Are you sure you want to continue?`,
        },
      }),
  };
};

const EditReportSidebarRecommendationGroup = observer(
  ({
    report,
    selectedSection,
    setSelectedSection,
    setIsAddingNeed,
    moveSelection,
  }: {
    report: AssessmentReport;
    selectedSection?: ReportEditorSection;
    setSelectedSection: (s: ReportEditorSection) => void;
    setIsAddingNeed: (b: boolean) => void;
    moveSelection: (delta: number) => void;
  }) => {
    const { reportGuidanceApi } = useContext(ApiStoreContext);
    const reportStore = useContext(ReportStoreContext);
    const [{ result: allNeeds }, { execute: fetchAllNeeds }] = useAsync(() =>
      reportGuidanceApi.getAllNeeds().catch(e => alertStore.setFailedProcess("fetch all needs", e)),
    );

    useMountEffect(fetchAllNeeds);

    const dataSourceItems: SidebarGroupSectionItem[] =
      allNeeds && allNeeds?.length > 0
        ? report.needGroups?.map(needGroup => ({
            type: {
              sectionType: getNeedTitle(needGroup, allNeeds || []),
              subType: "Recommendation",
            },
            action: removeNeedAction(
              reportStore,
              reportGuidanceApi,
              report,
              selectedSection?.sectionType === getNeedTitle(needGroup, allNeeds || []),
              needGroup.needIds,
              moveSelection,
            ),
          })) || []
        : [];

    const addAreaOfNeedButton = sidebarHeaderButton({ onClick: () => setIsAddingNeed(true) });

    return (
      <EditReportSidebarGroup
        headerText="Recommendations"
        headerAction={addAreaOfNeedButton}
        sectionItems={dataSourceItems}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
    );
  },
);

export default EditReportSidebarRecommendationGroup;
