import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ReportDocSection } from "@parallel/vertex/enums/report.org.enums";
import { HydratedEligibility } from "@parallel/vertex/types/report.org.guidance.types";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { ReportStoreContext } from "@/store";
import { SubHeaderValidationItem } from "../../header/SubHeaderControls";
import ConfigWindowLayout from "../ConfigWindowLayout";
import EligibilityConfigPanel from "./EligibilityConfigPanel";

const SingleEligibilityConfig = ({
  report,
  eligibility,
}: {
  report: AssessmentReport;
  eligibility?: HydratedEligibility;
}) => {
  const reportStore = useContext(ReportStoreContext);

  const headerControl =
    eligibility && reportStore.isEligibilityValid(eligibility?.id) ? (
      <SubHeaderValidationItem text="Must select at least one option per criteria" isValid={false} />
    ) : undefined;

  // prevent updating if eligibility section has been built
  const isLocked = report.sectionBuildHistory?.some(h => h.section === ReportDocSection.Eligibility) || false;

  if (!eligibility)
    return <div className="text-xl w-full h-full flex items-center justify-center">Eligibility not found</div>;
  return (
    <>
      <ConfigWindowLayout
        title={eligibility?.name || "Unknown Eligibility"}
        headerControls={headerControl}
        isSectionLocked={isLocked}
      >
        <div className="w-full h-full flex flex-col gap-6">
          <EligibilityConfigPanel eligibility={eligibility} report={report} isLocked={isLocked} key={eligibility.id} />
        </div>
      </ConfigWindowLayout>
    </>
  );
};

export default observer(SingleEligibilityConfig);
