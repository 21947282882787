import { ReportDocSection, ReportValueGroup } from "@parallel/vertex/enums/report.org.enums";
import { AssessmentReport, ReportId } from "@parallel/vertex/types/report.org.types";
import { InterviewFormType } from "@/api/report.api";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";
import { AuthorizedBaseAPI } from "./base.api";

export class ReportDocumentAPI extends AuthorizedBaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super(alertStore, userStore);
  }

  public buildDoc = (id: ReportId, update: Partial<AssessmentReport>): Promise<AssessmentReport> =>
    this.instance.post(`/report/document/${id}/build/doc`, { update }).then(r => r.data);

  public performTokenPresenceUpdates = (id: ReportId): Promise<void> =>
    this.instance.post(`/report/document/${id}/token-presence`);

  public injectValues = (id: ReportId, group: ReportValueGroup): Promise<AssessmentReport> =>
    this.instance.post(`/report/document/${id}/inject/${group}`).then(r => r.data);

  public injectInterviewFormValues = (id: ReportId, formType: InterviewFormType): Promise<AssessmentReport> =>
    this.instance.post(`/report/document/${id}/interview-form/inject`, { formType }).then(r => r.data);

  public buildSection = (id: ReportId, section: ReportDocSection): Promise<AssessmentReport> =>
    this.instance.post(`/report/document/${id}/build/${section}`).then(r => r.data);
}
