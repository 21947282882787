export const INTERVIEW_FORM_TYPES = ["student", "caregiver", "teacher"] as const;
export type InterviewFormType = (typeof INTERVIEW_FORM_TYPES)[number];

export enum ReportType {
  PsychComprehensive = "Comprehensive Psych Assessment",
  PsychAcademic = "Psych Academic Assessment",
  PsychReview = "Psych Review of Records",
  SocialEmotional = "Social-Emotional Assessment",
  SpeechComprehensive = "SLP Comprehensive",
  SpeechSpecialized1 = "SLP Specialized (1 Test)",
  SpeechSpecialized2 = "SLP Specialized (2 Tests)",
  PrivatePsych = "Private Psych", // deprecated
  SchoolPsychReEval = "School-Based Psych (Re-Evaluation)", // deprecated
  SchoolPsychInitial = "School-Based Psych (Initial)", // deprecated
}
export const AllValidReportTypes = [
  ReportType.PsychComprehensive,
  ReportType.PsychAcademic,
  ReportType.PsychReview,
  ReportType.SocialEmotional,
  ReportType.SpeechComprehensive,
  ReportType.SpeechSpecialized1,
  ReportType.SpeechSpecialized2,
];

export enum ReportLanguage {
  English = "English",
  Billingual = "Billingual",
}
export const AllValidReportLanguages = [ReportLanguage.English, ReportLanguage.Billingual];

const psychTemplateDocs = {
  [ReportLanguage.English]: "1fk9JpBzrg4oqcjfm7dTTZIQehp6p-o7iNtJHpET4KKs",
  [ReportLanguage.Billingual]: "1fk9JpBzrg4oqcjfm7dTTZIQehp6p-o7iNtJHpET4KKs",
};
const speechTemplateDocs = {
  [ReportLanguage.English]: "1TfoXP2ogT7Q4cv3037j2JQQNfq4gXL77iyUFDn3lgts",
  [ReportLanguage.Billingual]: "1TfoXP2ogT7Q4cv3037j2JQQNfq4gXL77iyUFDn3lgts",
};

export const ReportDocIds: Record<ReportType, Record<ReportLanguage, string>> = {
  [ReportType.PsychComprehensive]: psychTemplateDocs,
  [ReportType.PsychAcademic]: {
    [ReportLanguage.English]: "1R8ti_6VanFV6CD34aCDkhuL8vbksgA_s1pUxb8JZkPo",
    [ReportLanguage.Billingual]: "1R8ti_6VanFV6CD34aCDkhuL8vbksgA_s1pUxb8JZkPo",
  },
  [ReportType.PsychReview]: {
    [ReportLanguage.English]: "1pPcyBqcYzhYDv8ESxh2Rz2FSapXQh1R9lkHorIkVINk",
    [ReportLanguage.Billingual]: "1pPcyBqcYzhYDv8ESxh2Rz2FSapXQh1R9lkHorIkVINk",
  },
  [ReportType.SocialEmotional]: {
    [ReportLanguage.English]: "1xVlO-M-l19sPqamURS1hU-61oV0n_Wz5bTCtQYJ5LQ8",
    [ReportLanguage.Billingual]: "1xVlO-M-l19sPqamURS1hU-61oV0n_Wz5bTCtQYJ5LQ8",
  },
  [ReportType.SpeechComprehensive]: speechTemplateDocs,
  [ReportType.SpeechSpecialized1]: speechTemplateDocs,
  [ReportType.SpeechSpecialized2]: speechTemplateDocs,
  [ReportType.SchoolPsychInitial]: psychTemplateDocs,
  [ReportType.SchoolPsychReEval]: psychTemplateDocs,
  [ReportType.PrivatePsych]: {
    [ReportLanguage.English]: "12zNrcYhUauBBFV6JVfM6l78tjgzJGpyePHrVUVi7OvM",
    [ReportLanguage.Billingual]: "12zNrcYhUauBBFV6JVfM6l78tjgzJGpyePHrVUVi7OvM",
  },
};

export const isSpeech = (type: ReportType) => ReportDocIds[type] === speechTemplateDocs;

export enum ReportVertical {
  SchoolPsych = "SchoolPsych",
  Speech = "Speech",
}
export const ReportVerticalByType: Record<ReportType, ReportVertical | null> = {
  [ReportType.PsychComprehensive]: ReportVertical.SchoolPsych,
  [ReportType.PsychAcademic]: ReportVertical.SchoolPsych,
  [ReportType.PsychReview]: ReportVertical.SchoolPsych,
  [ReportType.SocialEmotional]: ReportVertical.SchoolPsych,
  [ReportType.SpeechComprehensive]: ReportVertical.Speech,
  [ReportType.SpeechSpecialized1]: ReportVertical.Speech,
  [ReportType.SpeechSpecialized2]: ReportVertical.Speech,
  [ReportType.SchoolPsychInitial]: ReportVertical.SchoolPsych,
  [ReportType.SchoolPsychReEval]: ReportVertical.SchoolPsych,
  [ReportType.PrivatePsych]: null,
};

export enum ReportStatus {
  ScoreEntry = "score-entry",
  Interpretation = "interpretation",
  Review = "review",
  Complete = "complete",

  //deprecated
  Editing = "editing",
  InReview = "in-review",
}

export const AllReportStatuses: ReportStatus[] = [
  ReportStatus.ScoreEntry,
  ReportStatus.Interpretation,
  ReportStatus.Review,
  ReportStatus.Complete,
];

type ReportStatusTransition = {
  previousStage: ReportStatus | undefined;
  previousName?: string;
  nextStage: ReportStatus | undefined;
  nextName?: string;
};

export const ReportStatusTransitions: Partial<Record<ReportStatus, ReportStatusTransition>> = {
  [ReportStatus.ScoreEntry]: {
    previousStage: undefined,
    nextStage: ReportStatus.Interpretation,
    nextName: "Ready for Interpretation",
  },
  [ReportStatus.Interpretation]: {
    previousStage: ReportStatus.ScoreEntry,
    previousName: "Back to Score Entry",
    nextStage: ReportStatus.Review,
    nextName: "Ready for Team Lead Review",
  },
  [ReportStatus.Review]: {
    previousStage: ReportStatus.Interpretation,
    previousName: "Back to Interpretation",
    nextStage: ReportStatus.Complete,
    nextName: "Ready for Upload",
  },
  [ReportStatus.Complete]: {
    previousStage: ReportStatus.Review,
    previousName: "Back to Review",
    nextStage: undefined,
  },
};

export const parseReportType = (data: any): ReportType => {
  if (data.type) return data.type;
  if (data.template === "School Psych") return ReportType.SchoolPsychInitial;
  return ReportType.PrivatePsych;
};

export enum ReportDocSection {
  InfoSourceTable = "info-source-table",
  Interpretations = "interpretations",
  Eligibility = "eligibility",
  Recommendations = "recommendations",
  Appendix = "appendix",
}

/**
 * IMPORTANT! - when adding a source to this enum, make sure that the new tokens related to the source
 * are referenced by their respective information sources @ `/common-packages/data/static/src/report/*`
 * (see https://app.asana.com/0/1201788657934400/1204500867681374/f for ideas on how to not require this any more)
 */
export enum ValueFileSource {
  WoodcockJohnson = "Woodcock-Johnson",
  BASC_Parent = "BASC Parent Report",
  BASC_Self = "BASC Self Report",
  BASC_Teacher = "BASC Teacher Report",
  BASC_Child_Interview = "BASC Child Interview",
  BRIEF_Parent = "BRIEF Parent Report",
  BRIEF_Self = "BRIEF Self Report",
  BRIEF_Teacher = "BRIEF Teacher Report",
  CELF_5_8 = "CELF-5 Ages 5-8",
  CELF_9_21 = "CELF-5 Ages 9-21",
  CELF_Meta = "CELF-5 Meta",
  CELF_Preschool = "Celf Preschool",
  EVT_3 = "EVT-3",
  PPVT_5 = "PPVT-5",
  IVA_Standard = "IVA Standard Report",
  IVA_Sustained = "IVA Sustained Attention Report",
  DIVA = "DIVA",
  Young_DIVA = "Young DIVA",
  GFTA_KLPA = "GFTA / KLPA",
  WISC = "WISC-V",
  Vineland_Parent = "Vineland Parent Report",
  Vineland_Teacher = "Vineland Teacher Report",
  SRS_Parent = "SRS Parent Report",
  SRS_Teacher = "SRS Teacher Report",
  WIAT = "WIAT-4",
}

export const AllValueFileSources: ValueFileSource[] = [
  ValueFileSource.WoodcockJohnson,
  ValueFileSource.BASC_Parent,
  ValueFileSource.BASC_Self,
  ValueFileSource.BASC_Teacher,
  ValueFileSource.BASC_Child_Interview,
  ValueFileSource.BRIEF_Parent,
  ValueFileSource.BRIEF_Self,
  ValueFileSource.BRIEF_Teacher,
  ValueFileSource.CELF_5_8,
  ValueFileSource.CELF_9_21,
  ValueFileSource.CELF_Meta,
  ValueFileSource.CELF_Preschool,
  ValueFileSource.EVT_3,
  ValueFileSource.PPVT_5,
  ValueFileSource.IVA_Standard,
  ValueFileSource.IVA_Sustained,
  ValueFileSource.DIVA,
  ValueFileSource.Young_DIVA,
  ValueFileSource.GFTA_KLPA,
  ValueFileSource.WISC,
  ValueFileSource.Vineland_Parent,
  ValueFileSource.Vineland_Teacher,
  ValueFileSource.SRS_Parent,
  ValueFileSource.SRS_Teacher,
  ValueFileSource.WIAT,
];

export enum ReportValueGroup {
  WJ_General = "WJ-General",
  WJ_Cluster = "WJ-Cluster",
  WJ_Variations = "WJ-Variations",
  WJ_Comparisons = "WJ-Comparisons",
  WJ_Observations = "WJ-Observations",
  BASC_Parent_Composite = "BASC-Parent-Composite",
  BASC_Parent_Scale = "BASC-Parent-Scale",
  BASC_Parent_Content_Norm = "BASC-Parent-Content-Norm",
  BASC_Parent_Indices = "BASC-Parent-Indices",
  BASC_Parent_EDQ = "BASC-Parent-EDQ",
  BASC_Parent_Clinical_Norm = "BASC-Parent-Clinical-Norm",
  BASC_Self_Child_Interview_Items = "BASC-Self-Child-Interview-Items",
  BASC_Self_Child_Interview_Summary = "BASC-Self-Child-Interview-Summary",
  BASC_Self_Composite = "BASC-Self-Composite",
  BASC_Self_Comparison = "BASC-Self-Comparison",
  BASC_Self_Scale = "BASC-Self-Scale",
  BASC_Self_Content_Norm = "BASC-Self-Content-Norm",
  BASC_Self_Indices = "BASC-Self-Indices",
  BASC_Self_Clinical_Norm = "BASC-Self-Clinical-Norm",
  BASC_Self_EDQ = "BASC-Self-EDQ",
  BASC_Teacher_Composite = "BASC-Teacher-Composite",
  BASC_Teacher_Scale = "BASC-Teacher-Scale",
  BASC_Teacher_Content_Norm = "BASC-Teacher-Content-Norm",
  BASC_Teacher_Clinical_Norm = "BASC-Teacher-Clinical-Norm",
  BASC_Teacher_EDQ = "BASC-Teacher-EDQ",
  BASC_Teacher_Indices = "BASC-Teacher-Indices",
  BRIEF_Parent_Summary = "BRIEF-Parent-Summary",
  BRIEF_Parent_Validity = "BRIEF-Parent-Validity",
  BRIEF_Self_Summary = "BRIEF-Self-Summary",
  BRIEF_Self_Validity = "BRIEF-Self-Validity",
  BRIEF_Teacher_Summary = "BRIEF-Teacher-Summary",
  BRIEF_Teacher_Validity = "BRIEF-Teacher-Validity",
  CELF_Scaled = "CELF-Scaled",
  CELF_Core_Language = "CELF-Core-Language",
  CELF_Criterion_Tests = "CELF-Criterion-Tests",
  CELF_Meta_Scaled = "CELF-Meta-Scaled",
  CELF_Meta_Index = "CELF-Meta-Index",
  CELF_Preschool_Scaled = "CELF-Preschool-Scaled",
  CELF_Preschool_Core_Language = "CELF-Preschool-Core-Language",
  CELF_Preschool_Criterion_Tests = "CELF-Preschool-Criterion-Tests",
  EVT_3_Summary = "EVT-3-Summary",
  PPVT_5_Summary = "PPVT-5-Summary",
  DIVA_Symptoms = "DIVA-Symptoms",
  DIVA_Summary = "DIVA-Summary",
  GFTA_Words = "GFTA-Words",
  GFTA_Sentences = "GFTA-Sentences",
  GFTA_Intelligibility = "GFTA-Intelligibility",
  HealthieInterviewForm = "Healthie-Interview-Form",
  HealthieCaregiverInterviewForm = "Healthie-Caregiver-Interview-Form",
  HealthieStudentInterviewForm = "Healthie-Student-Interview-Form",
  HealthieTeacherInterviewForm = "Healthie-Teacher-Interview-Form",
  IVA_Standard_Attention = "IVA-Standard-Attention",
  IVA_Sustained_Attention = "IVA-Sustained-Attention",
  KLPA_Summary = "KLPA-Summary",
  KLPA_Phonological = "KLPA-Phonological",
  WISC_Subtests = "WISC-Subtests",
  WISC_Scaled_Sums = "WISC-Scaled-Sums",
  Vineland_Parent_Domain = "Vineland-Parent-Domain",
  Vineland_Parent_Subdomain = "Vineland-Parent-Subdomain",
  Vineland_Teacher_Domain = "Vineland-Teacher-Domain",
  Vineland_Teacher_Subdomain = "Vineland-Teacher-Subdomain",
  SRS_Parent = "SRS-Parent",
  SRS_Teacher = "SRS-Teacher",
  WIAT_Core = "WIAT-Core",
  WIAT_Supplemental = "WIAT-Supplemental",
  WIAT_Subtests = "WIAT-Subtests",
}

export const AllReportValueGroups = [
  ReportValueGroup.HealthieInterviewForm,
  ReportValueGroup.HealthieCaregiverInterviewForm,
  ReportValueGroup.HealthieTeacherInterviewForm,
  ReportValueGroup.HealthieStudentInterviewForm,
  ReportValueGroup.WJ_General,
  ReportValueGroup.WJ_Cluster,
  ReportValueGroup.WJ_Variations,
  ReportValueGroup.WJ_Comparisons,
  ReportValueGroup.WJ_Observations,
  ReportValueGroup.BASC_Parent_Composite,
  ReportValueGroup.BASC_Parent_Scale,
  ReportValueGroup.BASC_Parent_Content_Norm,
  ReportValueGroup.BASC_Parent_Indices,
  ReportValueGroup.BASC_Parent_EDQ,
  ReportValueGroup.BASC_Parent_Clinical_Norm,
  ReportValueGroup.BASC_Self_Composite,
  ReportValueGroup.BASC_Self_Scale,
  ReportValueGroup.BASC_Self_Content_Norm,
  ReportValueGroup.BASC_Self_Indices,
  ReportValueGroup.BASC_Self_EDQ,
  ReportValueGroup.BASC_Self_Clinical_Norm,
  ReportValueGroup.BASC_Teacher_Composite,
  ReportValueGroup.BASC_Teacher_Scale,
  ReportValueGroup.BASC_Teacher_Content_Norm,
  ReportValueGroup.BASC_Teacher_Indices,
  ReportValueGroup.BASC_Teacher_EDQ,
  ReportValueGroup.BASC_Teacher_Clinical_Norm,
  ReportValueGroup.BASC_Self_Child_Interview_Items,
  ReportValueGroup.BASC_Self_Child_Interview_Summary,
  ReportValueGroup.BRIEF_Parent_Summary,
  ReportValueGroup.BRIEF_Parent_Validity,
  ReportValueGroup.BRIEF_Self_Summary,
  ReportValueGroup.BRIEF_Self_Validity,
  ReportValueGroup.BRIEF_Teacher_Summary,
  ReportValueGroup.BRIEF_Teacher_Validity,
  ReportValueGroup.CELF_Scaled,
  ReportValueGroup.CELF_Core_Language,
  ReportValueGroup.CELF_Criterion_Tests,
  ReportValueGroup.CELF_Meta_Scaled,
  ReportValueGroup.CELF_Meta_Index,
  ReportValueGroup.CELF_Preschool_Scaled,
  ReportValueGroup.CELF_Preschool_Core_Language,
  ReportValueGroup.CELF_Preschool_Criterion_Tests,
  ReportValueGroup.EVT_3_Summary,
  ReportValueGroup.PPVT_5_Summary,
  ReportValueGroup.DIVA_Symptoms,
  ReportValueGroup.DIVA_Summary,
  ReportValueGroup.GFTA_Words,
  ReportValueGroup.GFTA_Sentences,
  ReportValueGroup.GFTA_Intelligibility,
  ReportValueGroup.IVA_Standard_Attention,
  ReportValueGroup.IVA_Sustained_Attention,
  ReportValueGroup.KLPA_Summary,
  ReportValueGroup.KLPA_Phonological,
  ReportValueGroup.WISC_Subtests,
  ReportValueGroup.WISC_Scaled_Sums,
  ReportValueGroup.Vineland_Parent_Domain,
  ReportValueGroup.Vineland_Parent_Subdomain,
  ReportValueGroup.Vineland_Teacher_Domain,
  ReportValueGroup.Vineland_Teacher_Subdomain,
  ReportValueGroup.SRS_Parent,
  ReportValueGroup.SRS_Teacher,
  ReportValueGroup.WIAT_Core,
  ReportValueGroup.WIAT_Supplemental,
  ReportValueGroup.WIAT_Subtests,
];

// All interview forms
export const allClinicalInterviewValueGroups: ReportValueGroup[] = [
  ReportValueGroup.HealthieCaregiverInterviewForm,
  ReportValueGroup.HealthieStudentInterviewForm,
  ReportValueGroup.HealthieTeacherInterviewForm,
  ReportValueGroup.HealthieInterviewForm, // deprecated
];

export const isUpload = (g: ReportValueGroup) => !allClinicalInterviewValueGroups.includes(g);

export enum RecommendationIntent {
  EducationAccommodation = "Education Accommodation",
  TestingAccommodation = "Testing Accommodation",
  SchoolBasedService = "School-Based Service",
  Intervention = "Intervention",
  SpecificStrategy = "Specific Strategy",
}
export const AllRecommendationIntents = [
  RecommendationIntent.EducationAccommodation,
  RecommendationIntent.TestingAccommodation,
  RecommendationIntent.SchoolBasedService,
  RecommendationIntent.Intervention,
  RecommendationIntent.SpecificStrategy,
];

export const REPORT_DUE_REMINDER_DAYS = [45, 7, 1] as const;
export type ReportDueReminderDay = (typeof REPORT_DUE_REMINDER_DAYS)[number];
