import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { flatMap, groupBy, last, map, nth, uniq, uniqBy } from "lodash";
import { ReportValueGroup } from "@parallel/vertex/enums/report.org.enums";
import { ReportValue } from "@parallel/vertex/types/report.org.types";
import Collapsable from "@/components/common/elements/display/Collapsable";
import { toHeaderString } from "@/utils/assessment";
import ConfigWindowLayout from "../config/ConfigWindowLayout";

const TOKEN_ABBREVIATIONS = [
  "w",
  "ae",
  "rpi",
  "ss",
  "pr",
  "sd",
  "cog",
  "ol",
  "acha",
  "wj",
  "basc",
  "brief",
  "iva",
  "srs",
];

const WJ_TEST_NAMES: Partial<Record<string, string>> = {
  cog: "Tests of Cognitive Ability",
  ol: "Tests of Oral Language",
  acha: "Tests of Achievement",
};

const NO_HEADER_VALUE_GROUPS = [
  ReportValueGroup.BASC_Parent_Indices,
  ReportValueGroup.BASC_Self_Indices,
  ReportValueGroup.BASC_Teacher_Indices,
  ReportValueGroup.BASC_Self_Child_Interview_Items,
  ReportValueGroup.BASC_Self_Child_Interview_Summary,
  ReportValueGroup.DIVA_Symptoms,
  ReportValueGroup.HealthieInterviewForm,
  ReportValueGroup.HealthieCaregiverInterviewForm,
  ReportValueGroup.HealthieStudentInterviewForm,
  ReportValueGroup.HealthieTeacherInterviewForm,
];

type SectionOverride = {
  getTitle?: (tokenList: string[], metadata: any) => string;
  getHeader?: (tokenList: string[]) => string;
  getRowLeader?: (tokenList: string[]) => string;
};

// Common overrides used by all clinical interviews
const INTERVIEW_FORM_SECTION_OVERRIDES: SectionOverride = {
  getTitle: ts => ts[1],
  getRowLeader: ts => (ts.length >= 4 ? ts.slice(2).join(" - ") : nth(ts, -1) || ""),
};

const GROUP_SECTION_OVERRIDES: Partial<Record<ReportValueGroup, SectionOverride>> = {
  [ReportValueGroup.WJ_General]: {
    getTitle: ts => (ts.length === 3 ? "WJ General - Test-Specific" : "WJ General"),
  },
  [ReportValueGroup.WJ_Cluster]: {
    getTitle: (_, metadata) => WJ_TEST_NAMES[metadata.testKey] || "Unidentified Test",
  },
  [ReportValueGroup.DIVA_Symptoms]: {
    getTitle: ts => (nth(ts, -2) === "Criterion C" ? `DIVA ${ts[2]} Criterion C` : `DIVA ${ts[2]} Symptoms`),
  },
  [ReportValueGroup.HealthieInterviewForm]: INTERVIEW_FORM_SECTION_OVERRIDES,
  [ReportValueGroup.HealthieStudentInterviewForm]: INTERVIEW_FORM_SECTION_OVERRIDES,
  [ReportValueGroup.HealthieCaregiverInterviewForm]: INTERVIEW_FORM_SECTION_OVERRIDES,
  [ReportValueGroup.HealthieTeacherInterviewForm]: INTERVIEW_FORM_SECTION_OVERRIDES,
  [ReportValueGroup.SRS_Parent]: {
    getTitle: ts => (ts[2] === "Total" ? "SRS Parent Total" : "SRS Parent Subscale"),
  },
  [ReportValueGroup.SRS_Teacher]: {
    getTitle: ts => (ts[2] === "Total" ? "SRS Teacher Total" : "SRS Teacher Subscale"),
  },
};

type ReportValueSection = {
  title: string;
  headers?: string[];
  rows: {
    leader?: string;
    cells: (ReportValue | undefined)[];
  }[];
};

const capitalizeWords = (str: string, delineator: string) =>
  str
    .split(delineator)
    .map(w => `${w[0]?.toUpperCase()}${w.slice(1)}`)
    .join(delineator);

const prettyToken = (token: string) => {
  if (TOKEN_ABBREVIATIONS.includes(token)) return token.toUpperCase();
  const spaced = token.replace(/_/g, " ");
  const capitalized = [" ", "-"].reduce(capitalizeWords, spaced);
  return capitalized;
};

const getValueSections = (group: ReportValueGroup, values: ReportValue[]): ReportValueSection[] => {
  // prettier-ignore
  const {
    getTitle = (ts: string[]) => ts.length <= 3 ? undefined : ts.slice(0,-2).join(" "),
    getHeader = (ts: string[]) => last(ts),
    getRowLeader: getRowLeaderOverride,
  } = GROUP_SECTION_OVERRIDES[group] || {};

  const valuesWithTokenList = values.map(v => ({ ...v, tokenList: v.tokenPath.split(".").map(prettyToken) }));
  const valuesByTitle = groupBy(
    valuesWithTokenList,
    v => getTitle(v.tokenList, v.metadata || {}) || group.replace(/-/g, " "),
  );

  const sections: ReportValueSection[] = flatMap(valuesByTitle, (titledValues, baseTitle) => {
    // do not split sections by token list length if the table has no header
    const valuesByTokenListLength = groupBy(
      titledValues,
      v => !NO_HEADER_VALUE_GROUPS.includes(group) && v.tokenList.length,
    );
    const tokenListLengths: string[] = Object.keys(valuesByTokenListLength).sort((a, b) => parseInt(a) - parseInt(b));
    return tokenListLengths.map((lengthString, i) => {
      const values = valuesByTokenListLength[lengthString];
      const title = tokenListLengths.length === 1 ? baseTitle : `${baseTitle} - ${i + 1}`;

      if (values[0].tokenList.length <= 2 || NO_HEADER_VALUE_GROUPS.includes(group)) {
        const getRowLeader = getRowLeaderOverride || ((ts: string[]) => nth(ts, -1));
        return {
          title,
          rows: map(values, v => ({ leader: getRowLeader(v.tokenList), cells: [v] })),
        };
      }
      const headers = uniq(values.map(v => getHeader(v.tokenList))).filter(h => !!h) as string[];
      const getRowLeader = getRowLeaderOverride || ((ts: string[]) => nth(ts, -2));
      const valuesByLeader = groupBy(values, v => getRowLeader(v.tokenList));
      return {
        title,
        headers,
        rows: map(valuesByLeader, (cellValues, leader) => ({
          leader,
          cells: headers.map(h => cellValues.find(v => getHeader(v.tokenList) === h)),
        })),
      };
    });
  });
  return sections.map(s => ({
    ...s,
    rows: uniqBy(s.rows, "leader"),
  }));
};

const ReportValueTable = ({
  title,
  headers,
  rows,
  showInitially = false,
}: ReportValueSection & { showInitially?: boolean }) => {
  return (
    <div className="pb-4">
      <Collapsable title={title} showInitially={showInitially} useLeftChevron>
        <div className="overflow-x-auto text-sm">
          <table className="divide-y divide-gray-200 bg-white grow rounded-b-md w-full text-left">
            {headers && (
              <thead>
                <tr>
                  <th></th>
                  {headers.map((h, i) => (
                    <th className="p-3 font-semibold align-bottom" key={i}>
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="divide-y divide-gray-100">
              {rows.map(({ leader, cells }) => (
                <tr key={leader} className={cells.some(c => c && c.value && c.value !== "---") ? "" : "bg-gray-200"}>
                  <td className="p-3 font-semibold">{leader}</td>
                  {cells.map((c, i) => (
                    <td className="p-3" key={c?.tokenPath || i}>
                      {c && (
                        <>
                          <span className="w-full h-full cursor-pointer" id={c.tokenPath}>
                            {c.value}
                          </span>
                          <Tooltip anchorId={c.tokenPath} content={c.tokenPath} />
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Collapsable>
    </div>
  );
};

const ReportValues = ({
  group,
  pendingValues,
  injectedValues,
}: {
  group: ReportValueGroup;
  pendingValues?: ReportValue[];
  injectedValues?: ReportValue[];
}) => {
  const pendingSections = getValueSections(group, pendingValues || []);
  const injectedSections = getValueSections(group, injectedValues || []);
  const hasBoth = pendingSections.length > 0 && injectedSections.length > 0;
  return (
    <ConfigWindowLayout title={toHeaderString(group)} subtitle="View the uploaded scores below">
      <div className="w-full flex flex-col">
        {hasBoth && <h2 className="text-lg">Pending Values</h2>}
        {pendingSections.map(s => (
          <ReportValueTable
            {...s}
            showInitially={!hasBoth && pendingSections.length === 1}
            key={`pending - ${group} - ${s.title}`}
          />
        ))}
        {hasBoth && <h2 className="text-lg mt-4">Injected Values</h2>}
        {injectedSections.map(s => (
          <ReportValueTable
            {...s}
            showInitially={!hasBoth && injectedSections.length === 1}
            key={`injected - ${group} - ${s.title}`}
          />
        ))}
      </div>
    </ConfigWindowLayout>
  );
};

export default ReportValues;
