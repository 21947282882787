import { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { DocumentPlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { isArray } from "lodash";
import { AllValidReportTypes, ReportDocIds, ReportLanguage, ReportType } from "@parallel/vertex/enums/report.org.enums";
import { StudentRelatedUser } from "@parallel/vertex/types/user/student.types";
import { getReportReviewersByReportType } from "@parallel/vertex/util/report.org.util";
import Modal from "@/components/common/content/Modal";
import { Button, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/button/Button";
import { Checkbox } from "@/components/common/elements/input/Checkbox";
import CurrentUserClientSelect from "@/components/common/elements/select/CurrentUserClientSelect";
import Select from "@/components/common/elements/select/Select";
import config from "@/config";
import { AlertStoreContext, ApiStoreContext, loggerContext } from "@/store";
import { classNames, initLogger } from "@/utils";
import { currTimeOnDate, shortTimeZone } from "@/utils/datetime";
import { useLoggedRedirect } from "@/utils/redirect.utils";

type SelectedClient = {
  name: string;
  id: string;
  providers?: StudentRelatedUser[];
};

const logger = initLogger("CreateReportModal", loggerContext);

const CreateReportModal = ({
  inProgressClientIds,
  currUserId,
  setIsLoading,
  onClose,
}: {
  inProgressClientIds: string[];
  currUserId: string;
  setIsLoading: (isLoading: boolean) => void;
  onClose: () => void;
}) => {
  const { reportApi } = useContext(ApiStoreContext);
  const alertStore = useContext(AlertStoreContext);

  const [selectedClient, setSelectedClient] = useState<SelectedClient>();
  const [consentSignedDate, setConsentSignedDate] = useState<Date | null>(new Date());
  const [selectedReviewerUserId, setSelectedReviewerUserId] = useState<string>();
  const [selectedReportType, setSelectedReportType] = useState(ReportType.PsychComprehensive);
  const [isEnglish, setIsEnglish] = useState(true);

  const redirect = useLoggedRedirect(logger);

  const selectedReportLanguage = isEnglish ? ReportLanguage.English : ReportLanguage.Billingual;
  const templateDocId = ReportDocIds[selectedReportType][selectedReportLanguage];

  const handleClientClear = () => {
    setSelectedClient(undefined);
    setSelectedReviewerUserId(undefined);
  };

  const startReport = async () => {
    if (!selectedClient || !consentSignedDate) return;
    try {
      setIsLoading(true);
      const reportCreationRequest = {
        clientUserId: selectedClient.id,
        startedByUserId: currUserId,
        type: selectedReportType,
        language: selectedReportLanguage,
        consentSignedAt: consentSignedDate,
        reviewerUserId: selectedReviewerUserId,
      };
      const report = await logger.wrapOperation(
        "createReport",
        reportApi.create(reportCreationRequest),
        reportCreationRequest,
      );

      redirect(`/assessment/report/${report.id}`, "new report");
    } catch (e) {
      setIsLoading(false);
      alertStore.setFailedProcess("create assessment report", e as Error);
    }
  };

  const availableReviewers = getReportReviewersByReportType(config, selectedReportType);
  useEffect(() => {
    if (availableReviewers.some(r => r.userId === selectedReviewerUserId)) return;
    setSelectedReviewerUserId(availableReviewers[0]?.userId);
  }, [selectedReportType]);

  const isClientValid = !!selectedClient && !inProgressClientIds.some(c => c === selectedClient.id);
  let warning: string = "";
  if (selectedClient) {
    if (!isClientValid) {
      warning =
        "You already have a report in progress for this student. Please complete the existing report before starting a new report for this student.";
    } else if (!consentSignedDate) {
      warning = "Consent signed date is required";
    }
  }

  return (
    <Modal title="New Report" onClose={onClose} containerStyle={{ width: "40rem", maxWidth: "75%" }}>
      <div className="w-full h-full flex flex-col gap-8">
        <div className="w-full">
          <p className="mb-2">Select a Student</p>
          <div className="w-full h-20">
            {!selectedClient ? (
              <CurrentUserClientSelect
                userSelected={({ userId: id, fullName: name, providers }) => setSelectedClient({ id, name, providers })}
                onShowToggle={isShowing => isShowing && setSelectedClient(undefined)}
                focusOnMount={true}
              />
            ) : (
              <div className="w-full h-full p-4 bg-white rounded-md border border-gray-300 flex flex-row place-items-center">
                <div className="flex flex-col grow">
                  <div className="text-lg">{selectedClient?.name}</div>
                  <div className="text-gray-400">{selectedClient?.id}</div>
                </div>
                <XMarkIcon className="w-6 h-6 cursor-pointer" onClick={() => handleClientClear()} />
              </div>
            )}
          </div>
        </div>

        <div className={classNames("w-full flex flex-col gap-2", isClientValid ? "visible" : "invisible")}>
          <p>
            Select a Consent Signed Date <span className="text-sm">({shortTimeZone(new Date())})</span>
          </p>
          <DatePicker
            onChange={value => {
              const date = isArray(value) ? value[0] : value;
              setConsentSignedDate(date && currTimeOnDate(date));
            }}
            value={consentSignedDate}
          />
        </div>

        <div className={classNames("w-full", isClientValid ? "visible" : "invisible")}>
          <div className="flex flex-col gap-2">
            <p>Select a Report Type</p>
            <Select
              label="type-select"
              options={AllValidReportTypes.map(t => ({ name: t, value: t }))}
              value={selectedReportType}
              onChange={selected => setSelectedReportType(selected as ReportType)}
            />
            <Checkbox
              id="report-language"
              label="Is Bilingual?"
              isChecked={!isEnglish}
              toggle={() => setIsEnglish(!isEnglish)}
            />
            <a
              href={`https://docs.google.com/document/d/${templateDocId}/view`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-ocean underline italic"
            >
              Preview Template
            </a>
          </div>
        </div>

        <div
          className={classNames(
            "w-full flex flex-col gap-2",
            isClientValid && availableReviewers.length > 0 ? "visible" : "invisible",
          )}
        >
          <>
            <p className="">Select a Reviewer</p>
            <Select
              label="reviewer-select"
              options={availableReviewers.map(r => ({ name: r.name, value: r.userId }))}
              value={selectedReviewerUserId}
              disabled={availableReviewers.length < 2}
              onChange={selected => setSelectedReviewerUserId(selected)}
            />
          </>
        </div>

        <div
          className={classNames(
            warning
              ? "visible flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1"
              : "invisible",
          )}
        >
          {warning}
        </div>

        <Button
          text="Start Report"
          icon={<DocumentPlusIcon />}
          className={classNames(warning ? "invisible" : "visible", DEFAULT_BUTTON_CLASS)}
          styleOverrides={{ width: "100%" }}
          onClick={startReport}
        />
      </div>
    </Modal>
  );
};

export default CreateReportModal;
