import { keyBy } from "lodash";
import { Need } from "@parallel/vertex/types/report.org.guidance.types";
import { NeedGroup } from "@parallel/vertex/types/report.org.types";
import { getCommaList } from "@parallel/vertex/util/string.util";

export const groupNeedsById = (needs?: Need[]) => keyBy(needs || [], "id");

export const getNeedTitle = (needGroup: NeedGroup, allNeeds: Need[]): string => {
  const needsById = groupNeedsById(allNeeds);
  return getCommaList([...needGroup.needIds.map(needId => needsById[needId]?.name || `Need ${needId}`)]);
};
