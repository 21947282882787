import { useContext, useState } from "react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { StudentRelatedUser } from "@parallel/vertex/types/user/student.types";
import Modal from "@/components/common/content/Modal";
import { Button, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/button/Button";
import { ProviderSelect } from "@/components/common/elements/select/ProviderSelect";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { classNames } from "@/utils";

const ShareReportModal = ({ onClose, report }: { report: AssessmentReport; onClose: () => void }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const [provider, setProvider] = useState<StudentRelatedUser>();
  const [sharedProvider, setSharedProvider] = useState<string | undefined>();

  const hasReportBeenBuilt = !!report.docId;
  const providerId = provider?.userId;
  const shareReport = providerId
    ? () =>
        reportStore.updateRemoteReport({
          name: "share report",
          fn: () => reportApi.share(report.id, providerId),
          sideEffect: () => setSharedProvider(provider.fullName || "Unknown"),
        })
    : undefined;

  const msg = sharedProvider
    ? `Successfully shared with ${sharedProvider}!`
    : !hasReportBeenBuilt && `Finishing building your report template before sharing it.`;

  return (
    <Modal
      title="Share Report With Provider"
      onClose={onClose}
      containerStyle={{
        width: "70rem",
        maxWidth: "40%",
      }}
    >
      <ProviderSelect
        clientId={report.clientUserId}
        startedByUserId={report.startedByUserId}
        selectedProvider={provider}
        setSelectedProvider={setProvider}
      />
      <div className="mt-4">
        <span>{msg}</span>
      </div>
      <div
        className={classNames("flex flex-col items-center mt-2 sm:mt-6 min-w-2", sharedProvider ? "pt-10" : "pt-20")}
      >
        <Button
          text="Share Report"
          icon={<UserPlusIcon />}
          onClick={shareReport}
          disabled={!provider?.["email"] || !hasReportBeenBuilt}
          className={classNames("disabled:opacity-75", DEFAULT_BUTTON_CLASS)}
        />
      </div>
    </Modal>
  );
};

export default observer(ShareReportModal);
