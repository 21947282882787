import { useContext, useEffect, useState } from "react";
import { MoonLoader } from "react-spinners";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { sortBy, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import { ReportDocSection, ReportStatus } from "@parallel/vertex/enums/report.org.enums";
import { Recommendation } from "@parallel/vertex/types/report.org.guidance.types";
import { AssessmentReport, NeedGroup } from "@parallel/vertex/types/report.org.types";
import { findNeedGroup, getRecommendationsByNeedGroup } from "@parallel/vertex/util/report.org.util";
import MultiSelectWindow, { ToggleItem } from "@/components/common/windows/MultiSelectWindow";
import { AlertStoreContext, ApiStoreContext, ReportStoreContext } from "@/store";
import ConfigWindowLayout from "../ConfigWindowLayout";

const SingleRecommendationConfig = ({
  report,
  needGroup,
  needGroupName,
}: {
  report: AssessmentReport;
  needGroup: NeedGroup;
  needGroupName?: string;
}) => {
  const { reportGuidanceApi } = useContext(ApiStoreContext);
  const alertStore = useContext(AlertStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const [{ result: hydratedNeeds }, { execute: fetchAllNeeds }] = useAsync(() =>
    reportGuidanceApi
      .getNeedsByIds(needGroup.needIds)
      .catch(e => alertStore.setFailedProcess("fetch required needs", e)),
  );

  useMountEffect(fetchAllNeeds);
  useEffect(() => {
    const recommendationsForGroup = getRecommendationsByNeedGroup(hydratedNeeds || [], needIds);
    setRecommendations(sortBy(recommendationsForGroup, rec => rec.text));
  }, [hydratedNeeds]);

  const { needIds } = needGroup;
  const selectedNeedGroup = findNeedGroup(report, needIds);

  const [recommendations, setRecommendations] = useState<Recommendation[]>();

  const toggleItems: ToggleItem[] =
    recommendations?.map(r => ({
      key: r.id,
      name: r.text,
      isEnabled: selectedNeedGroup?.group?.recommendationIds?.includes(r.id),
    })) || [];

  const selectRecommendations = (recommendationIds: string[]) => {
    setIsLoading(true);

    const needIds = hydratedNeeds?.map(need => need.id) || [];

    reportStore.updateRemoteReport({
      name: "add recommendations",
      fn: () => reportGuidanceApi.upsertRecommendationIds(report.id, needIds, uniq(recommendationIds)),
      updateLocalConfig: true,
    });
    setIsLoading(false);
  };

  const isLocked =
    report.status === ReportStatus.Complete ||
    report.sectionBuildHistory?.some(h => h.section === ReportDocSection.Recommendations);

  if (!report || !needGroup || !hydratedNeeds) {
    return (
      <div className="w-full h-full grid place-items-center">
        <MoonLoader />
      </div>
    );
  }

  return (
    <ConfigWindowLayout
      title={needGroupName || "Unknown NeedGroup"}
      isSectionLocked={isLocked}
      subtitle="Select recommendations tied to these areas of need"
    >
      <div className="w-full h-full flex flex-col gap-6">
        <div className="w-full flex flex-col divide-y">
          {toggleItems.length === 0 ? (
            <div className="text-xl w-full h-full flex items-center justify-center">
              No recommendations exist for this area of need
            </div>
          ) : (
            <MultiSelectWindow
              isSectionLocked={isLocked}
              items={toggleItems}
              setEnabledItems={(enabledRecommendationKeys: string[]) => {
                if (isLoading) return;
                selectRecommendations(enabledRecommendationKeys);
              }}
            />
          )}
        </div>
      </div>
    </ConfigWindowLayout>
  );
};

export default observer(SingleRecommendationConfig);
