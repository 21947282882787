import { capitalize, last } from "lodash";

export const formatNounWithCount = (count: number, noun: string) => `${count} ${noun}${count === 1 ? "" : "s"}`;

export const getCommaList = (
  strs: string[],
  { noun, delimiter = "and" }: { noun?: string; delimiter?: string } = {},
) => {
  const prefix = !noun ? "" : ` ${noun}${strs.length > 1 ? "s" : ""} `;

  if (strs.length <= 2) return `${prefix}${strs.join(` ${delimiter} `)}`;

  const commaSeparated = strs.slice(0, strs.length - 1);
  return `${prefix}${commaSeparated.join(", ")} ${delimiter} ${last(strs)}`;
};

export const getInitials = ({ firstName, lastName }: { firstName: string; lastName: string }) =>
  `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

export const getFullNameInitials = (fullName: string) => {
  const names = fullName.split(" ");
  const lastName = last(names);
  return `${names[0][0].toUpperCase()}${lastName ? lastName[0].toUpperCase() : ""}`;
};

export const getPossessiveNoun = (noun: string) => (last(noun) === "s" ? `${noun}'` : `${noun}'s`);

export const toTitleCase = (string: string) => {
  if (string.toLowerCase() === "slpa") return "SLPA";
  return string
    .split(/[ _]/)
    .map(s => capitalize(s.toLowerCase()))
    .join(" ");
};

// at least 8 characters + include an uppercase letter, number, and special character
export const validatePassword = (s: string) => {
  const length = s.length >= 8;
  const capitalLetter = !!s.match(/[A-Z]/);
  const specialChar = !!s.match(/[^A-Za-z0-9]/);
  return {
    checks: { length, capitalLetter, specialChar },
    isValid: length && capitalLetter && specialChar,
  };
};
