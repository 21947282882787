import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import config from "@/config";
import App from "./App";
import "./main.css";

const isProd = config.parallelEnv === "production";

if (config.parallelEnv !== "local") {
  Sentry.init({
    dsn: config.sentryEndpoint,
    environment: config.parallelEnv,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: isProd,
        blockAllMedia: isProd,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["org-sentry-application-key"],
        behaviour: "apply-tag-if-contains-third-party-frames",
      }),
    ],
    profilesSampleRate: isProd ? 0.1 : 1.0,
    replaysOnErrorSampleRate: isProd ? 0.1 : 1.0,
    replaysSessionSampleRate: isProd ? 0.1 : 1.0,
    tracesSampleRate: isProd ? 0.1 : 1.0,
    beforeSend: (event, hint) => {
      if (hint.originalException instanceof AxiosError) {
        event.tags = { ...event.tags, warningType: "axios" };
        event.level = "warning";
      }
      return event;
    },
  });
}

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <StrictMode>
      <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
        <GoogleOAuthProvider clientId={config.oauthClientId}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Sentry.ErrorBoundary>
    </StrictMode>,
  );
})();
