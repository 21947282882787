import { useContext } from "react";
import { MinusIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { AssessmentReport } from "@parallel/vertex/types/report.org.types";
import { ReportGuidanceAPI } from "@/api/report.guidance.api";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { ReportStore } from "@/store/report.store";
import { ReportEditorSection } from "../EditReport";
import EditReportSidebarGroup, { SidebarGroupSectionItem, sidebarHeaderButton } from "./EditReportSidebarGroup";

export const removeEligibilityAction = (
  reportStore: ReportStore,
  reportGuidanceApi: ReportGuidanceAPI,
  report: AssessmentReport,
  isGroupSelected: boolean,
  eligibilityId: string,
  moveSelection: (delta: number) => void,
) => {
  const titleText = "Remove Eligibility";

  return {
    icon: <MinusIcon />,
    helpText: titleText,
    fn: () =>
      reportStore.updateRemoteReport({
        name: `remove eligibility - ${eligibilityId}`,
        fn: () => reportGuidanceApi.deleteEligibility(report.id, eligibilityId),
        sideEffect: _updatedReport => {
          if (!isGroupSelected) return;
          moveSelection(1);
        },
        confirmSpec: {
          title: titleText,
          prompt: `The eligibility will be removed from the report. Are you sure you want to continue?`,
        },
      }),
  };
};

const EditReportSidebarEligibilityGroup = observer(
  ({
    report,
    selectedSection,
    setSelectedSection,
    setIsAddingEligibility,
    moveSelection,
  }: {
    report: AssessmentReport;
    selectedSection?: ReportEditorSection;
    setSelectedSection: (s: ReportEditorSection) => void;
    setIsAddingEligibility: (b: boolean) => void;
    moveSelection: (delta: number) => void;
  }) => {
    const { reportGuidanceApi } = useContext(ApiStoreContext);

    const reportStore = useContext(ReportStoreContext);
    const { reportEligibilities } = reportStore;
    const dataSourceItems: SidebarGroupSectionItem[] =
      reportEligibilities?.map(eligibility => ({
        type: { sectionType: eligibility.canonical.name, subType: "Eligibility" },
        action: removeEligibilityAction(
          reportStore,
          reportGuidanceApi,
          report,
          selectedSection?.sectionType === eligibility.canonical.name,
          eligibility.canonical.id,
          moveSelection,
        ),
      })) || [];

    const addEligibilityButton = sidebarHeaderButton({ onClick: () => setIsAddingEligibility(true) });

    return (
      <EditReportSidebarGroup
        headerText="Eligibility"
        headerAction={addEligibilityButton}
        sectionItems={dataSourceItems}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
    );
  },
);

export default EditReportSidebarEligibilityGroup;
